import React, { FC, useRef, useEffect } from 'react';

import {
  Container, Title, Text, Plus,
} from './styled';

interface IValueItem {
  title: string;
  text: string;
  onOpen: () => void;
  isOpened: boolean;
  className?: string;
}

const ValueItem: FC<IValueItem> = ({
  className, title, text, onOpen, isOpened,
}) => {
  const textRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const wrapper = textRef.current;

    if (wrapper) {
      const height = isOpened ? wrapper.scrollHeight : 0;
      wrapper.style.height = `${height}px`;
    }
  }, [isOpened]);

  return (
    <Container
      className={`${className} ${isOpened ? 'opened' : ''}`}
      onClick={onOpen}
    >
      <Title>{title}</Title>
      <Plus />
      <Text ref={textRef}>{text}</Text>
    </Container>
  );
};

export default ValueItem;
