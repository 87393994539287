import React, { FC, useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IWidget } from 'interfaces/widgets';

import {
  Container, Header, Space, SpaceOne, Title, Text, Item,
} from './style';

const query = graphql`
  query {
    strapiWidgets(title: {eq: "values"}, page: {eq: "culture"}) {
      title
      header
      strapiId
      text {
        id
        title
        text
      }
      slide {
        header
        id
        title
      }
    }
  }
`;

interface IValues {
  strapiWidgets: IWidget<null, null>;
}

const Values: FC = () => {
  const [opened, setOpen] = useState(-1);

  const handleOpen = (index: number) => {
    if (opened === index) {
      setOpen(-1);
    } else {
      setOpen(index);
    }
  };

  return (
    <StaticQuery
      query={query}
      render={(data: IValues) => {
        if (!data?.strapiWidgets) return null;
        const { header, text, slide } = data?.strapiWidgets;

        return (
          <Container withPaddings>
            <Header>{header}</Header>
            <Title>
              {text.find(({ title }) => title === 'header')?.text}
            </Title>

            {slide.map((item, index) => (
              <Item
                key={`values-${item.id}`}
                title={item.title}
                text={item.header}
                isOpened={opened === index}
                onOpen={() => handleOpen(index)}
              />
            ))}

            {text?.filter(({ title }) => title !== 'header')?.map((item) => (
              <Text key={item.id}>{item.text}</Text>
            ))}

            <Space />
            <SpaceOne />
          </Container>
        );
      }}
    />
  );
};

export default Values;
