import styled from '@emotion/styled';

import { MIN_4K, MIN_2K } from 'constants/sizes';
import { MiddleText } from 'UI';

export const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  align-self: start;
  gap: 2em;
`;

export const Department = styled.p`
  color: var(--red);
  padding-bottom: 0.5em;
  cursor: pointer;
`;

export const CloseIcon = styled.span`
  display: block;
  width: 0.8em;
  height: 0.8em;
  position: relative;
  opacity: 0;
  transition: all 0.4s ease-in-out;
`;

const Line = styled.span`
  display: block;
  position: absolute;
  width: 1px;
  left: 50%;
  height: 100%;
  background: var(--red);

  @media (min-width: ${MIN_2K}px) {
    width: 2px;
  }

  @media (min-width: ${MIN_4K}px) {
    width: 4px;
  }
`;

export const LineOne = styled(Line)``;

export const LineTwo = styled(Line)`
  transform: rotate(90deg);
`;

export const PositionWrapper = styled.div`
  position: relative;
  overflow: hidden;
  transition: height 0.8s ease-in-out;
`;

export const Title = styled(MiddleText)`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  :hover {
    ${CloseIcon} {
      opacity: 1;
    }
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1em;
  border-bottom: 1px solid black;

  &.active {
    ${CloseIcon} {
      opacity: 1;
      transform: rotate(45deg);
    }
  }
`;

export const MoreIcon = styled.span`
  display: inline-block;
  position: relative;
  margin-left: 8px;
  margin-right: 2px;
  width: 6px;
  height: 6px;
  transform: rotate(45deg) translate(-2px, -2px);
  border-bottom: 2px solid;
  border-right: 2px solid;
  color: var(--red);

  &.opened {
    border-left: 2px solid;
    border-top: 2px solid;
    border-bottom: none;
    border-right: none;
    transform: rotate(45deg) translate(-1px, -1px);
    top: 2px;
  }
`;

export const ShowMore = styled.p`
  justify-self: end;
  cursor: pointer;
`;
