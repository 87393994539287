import React, {
  FC, useRef, FormEvent,
} from 'react';

import {
  SearchIcon, Input, Container, Label,
} from './Search.styled';

interface IFilter {
  onChange: (text: string) => void;
  className?: string;
}

const Search: FC<IFilter> = ({ className = '', onChange }) => {
  const inputRef = useRef<HTMLInputElement>();

  const handleChange = ({ target }: FormEvent<HTMLInputElement>) => {
    const { value } = target as HTMLInputElement;
    onChange(value.trim()?.toLowerCase());
  };

  return (
    <Container className={className}>
      <SearchIcon width="1em" />
      <Input
        ref={inputRef}
        type="search"
        inputMode="search"
        onChange={handleChange}
        required
        pattern="\S+.*"
        id="jobs-search"
      />
      <Label htmlFor="jobs-search">Search</Label>
    </Container>
  );
};

export default Search;
