import React, {
  FC, useRef, useEffect,
} from 'react';

import { IStrapiVacancy } from 'interfaces/vacancy';

import Position from '../Position/Position';

import { PositionWrapper } from './styled';

interface IPositionContainer {
  position: IStrapiVacancy;
  opened: boolean;
}

const PositionContainer: FC<IPositionContainer> = ({ position, opened = false }) => {
  const wrapperRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const wrapper = wrapperRef.current;
    const height = opened ? `${wrapper.scrollHeight}px` : '0px';
    wrapper.style.height = height;
  }, [opened]);

  return (
    <PositionWrapper ref={wrapperRef}>
      <Position position={position} />
    </PositionWrapper>
  );
};

export default PositionContainer;
