import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

import { MAX_MOB_WIDTH, MAX_TABLET_WIDTH, MOBILE_TOP_MENU_HEIGHT } from 'constants/sizes';
import { Layout, Video as UIVideo } from 'UI';

const contentAppear = keyframes`
  0%    { visibility: hidden; }
  100%  { visibility: visible; }
`;

const textIn = keyframes`
  0%    { opacity: 0; }
  20%   { opacity: 0; }
  100%  { opacity: 1; }
`;

export const SlideTitle = styled.h2`
  color: var(--white);

  grid-column: 1/7;
  grid-row: 1;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/-1;
  }
`;

export const SlideText = styled.p`
  color: var(--white);

  grid-column: 1/5;
  grid-row: 2;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/-1;
  }
`;

export const Video = styled(UIVideo)`
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/-1;
  }
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  transition: opacity 0.2s;
  will-change: opacity, visibility;

  @media (max-width: ${MAX_TABLET_WIDTH}px) and (min-height: 1020px) {
    padding-bottom: 75%;
    height: 0;
    top: auto;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    height: 100%;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) and (min-height: 720px) {
    padding-bottom: 65%;
    height: 0;
    top: auto;
  }
`;

export const SliderContent = styled(Layout)`
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: 0.3s ease-in-out;
  will-change: opacity, visibility;

  padding-bottom: 40px;
  left: 80px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  align-content: end;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) and (min-height: 1020px) {
    padding-bottom: calc(75% + 40px);
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding-bottom: 70px;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) and (min-height: 720px) {
    padding-bottom: calc(65% + 20px);
  }
`;

export const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  animation-delay: 0.6s;
  will-change: visibility;

  ::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -2;

    background: #000;

    @media (max-width: ${MAX_MOB_WIDTH}px) {
      top: ${MOBILE_TOP_MENU_HEIGHT}px;
    }
  }

  :not(.active) {
    animation: ${contentAppear} 0s ease-out;
    animation-delay: 1s;
    animation-direction: reverse;
    animation-fill-mode: forwards;

    ${Background} {
      display: none;
    }
  }

  &.active {
    animation: ${contentAppear} 0s ease-out;
    animation-delay: 0s;
    animation-direction: normal;
    animation-fill-mode: forwards;
    visibility: visible;
    z-index: -1;

    ${Background} {
      display: block;
    }

    ${SliderContent} {
      visibility: visible;
      animation: ${textIn} 1s ease-in-out;
      opacity: 1;
    }
  }
`;
