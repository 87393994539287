import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { StaticQuery, graphql } from 'gatsby';

import { IQueryPage } from 'interfaces/page';

import Head from 'components/Layout/Head/Head';

import { setActiveBlock, setPreviousBlock } from 'store/actions';

import ImageBlock from 'components/blocks/CulturePage/ImageBlock/ImageBlock';
import WelcomeBlock from 'components/blocks/CulturePage/WelcomeBlock/WelcomeBlock';
import Values from 'components/blocks/CulturePage/Values/Values';
import WayRavers from 'components/blocks/CulturePage/WayRavers/WayRavers';
import Team from 'components/blocks/CulturePage/Team/Team';
// import Management from 'components/blocks/CulturePage/Management/Management';
import Map from 'components/blocks/CulturePage/Map/Map';
import TeamsSlider from 'components/blocks/CulturePage/TeamsSlider/TeamsSlider';
import Jobs from 'components/Jobs/Jobs';
import { Intersection, ScaleVisible } from 'UI';

const CulturePage: FC = () => {
  const dispatch = useDispatch();

  const setBlock = ({ title, visibility }: ISetBlock) => {
    if (visibility) {
      dispatch(setActiveBlock(`#${title}`));
    } else {
      dispatch(setPreviousBlock(`#${title}`));
    }
  };

  return (
    <>
      <Intersection id="mission" way={setBlock}>
        <WelcomeBlock />

        <ScaleVisible>
          <ImageBlock />
        </ScaleVisible>
      </Intersection>

      <Intersection id="team" way={setBlock}>
        <Team />

        <TeamsSlider />

        {/*
          <Management />
        */}
      </Intersection>

      <Intersection id="values" way={setBlock}>
        <Values />
      </Intersection>

      <Intersection id="culture" way={setBlock}>
        <WayRavers />
      </Intersection>

      <Intersection id="jobs" way={setBlock}>
        <Jobs />
      </Intersection>

      <Intersection id="locations" way={setBlock}>
        <Map />
      </Intersection>
    </>
  );
};

const query = graphql`
  query {
    page: strapiCulturePage {
      meta {
        ...StrapiMetaFragment
      }
    }
  }
`;

const CulturePageQuery: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IQueryPage) => {
      const siteInfo = data.page.meta;

      return (
        <>
          <Head
            title={siteInfo.title}
            description={siteInfo.description}
            preview={siteInfo.preview}
          />
          <CulturePage />
        </>
      );
    }}
  />
);

export default CulturePageQuery;
