import styled from '@emotion/styled';

import { GatsbyPicture } from 'UI';

export const Picture = styled(GatsbyPicture)`
  width: 100%;
  height: 100vh;
  display: block;
  object-fit: cover;

  img,
  source {
    height: 100%;
    object-fit: cover;
  }
`;

export const Container = styled.div`
  z-index: 10;
  position: relative;
`;
