import styled from '@emotion/styled';

import Markdown from 'markdown-to-jsx';

import { MAX_MOB_WIDTH, MAX_TABLET_WIDTH } from 'constants/sizes';
import { Layout as UILayout, SectionTitle } from 'UI';

export const Layout = styled(UILayout)`
  min-height: 80vh;
  padding-top: 7em;
  padding-bottom: 7em;
  align-content: center;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding-top: 20px;
    padding-bottom: 0;
  }
`;

export const Header = styled(SectionTitle)`
  grid-row: 1;
  grid-column: 1/-1;

  color: var(--red);
`;

export const Title = styled.h3`
  grid-column: 1/7;
  grid-row: 2;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    padding-bottom: 100px;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding-bottom: 20px;
  }
`;

const Paragraph = styled(Markdown)`
  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 4/-1;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 2/-1;
  }
`;

export const TextOne = styled(Paragraph)`
  grid-column: 7/9;
  grid-row: 3;
`;

export const TextTwo = styled(Paragraph)`
  grid-column: 9/11;
  grid-row: 3;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-row: 4;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    flex-direction: column;
  }
`;
