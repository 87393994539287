import styled from '@emotion/styled';

import { MAX_TABLET_WIDTH, MAX_MOB_WIDTH } from 'constants/sizes';

export const Container = styled.div``;

export const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 40px;
  color: var(--gray);

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    position: absolute;
    background: #fff;
    left: 24px;
    right: 24px;
    top: 40px;
    padding: 0 24px;
    grid-auto-flow: column;
    max-height: 0;
    transition: 0.4s ease-in-out;
    overflow: hidden;
    z-index: 2;

    &.opened {
      padding: 24px 24px;
      max-height: 1000px;
    }
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    top: 100px;
    left: 8px;
    right: 8px;
    padding: 0 20px;
    gap: 35px;

    &.opened {
      padding: 20px 20px;
    }
  }
`;

export const PositionsList = styled.ul`
  display: grid;
  grid-auto-flow: row;
  gap: 0.5em;
  margin-bottom: auto;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-row: 1;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-row: auto;
  }
`;

export const CloseIcon = styled.span`
  display: inline-block;
  position: relative;
  margin-left: 8px;
  margin-right: 2px;
  width: 6px;
  height: 6px;
  transform: rotate(45deg) translate(-2px, -2px);
  border-bottom: 2px solid;
  border-right: 2px solid;
  color: var(--red);

  &.opened {
    border-left: 2px solid;
    border-top: 2px solid;
    border-bottom: none;
    border-right: none;
    transform: rotate(45deg) translate(-1px, -1px);
    top: 2px;
  }
`;

export const ExpandButton = styled.label`
  position: relative;
  margin: 0;
  border: none;
  background: none;
  display: none;
  cursor: pointer;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  color: var(--red);
  height: 24px;
  line-height: 24px;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    display: flex;
  }
`;

export const CloseButton = styled.label`
  width: 100%;
  background: #000;
  color: #fff;
  height: 48px;
  text-align: center;
  display: none;
  cursor: pointer;
  line-height: 48px;

  :hover {
    background: #222;
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    display: block;
    grid-row: 2;
    grid-column: 1/3;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-row: 3;
  }
`;
