import styled from '@emotion/styled';

import {
  MIN_4K, MAX_MOB_WIDTH, MAX_TABLET_MEDIUM_WIDTH, MAX_TABLET_WIDTH,
} from 'constants/sizes';
import { Container as MenuContainer } from 'UI/ScrollableSlider/SliderMenu/styled';

export const TopText = styled.p`
  white-space: pre;
  color: var(--white);
  max-width: 122px;

  margin-top: 10px;
  z-index: 1;

  @media (max-width: ${MAX_TABLET_MEDIUM_WIDTH}px) {
    margin-top: 60px;
    grid-column: -1;
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    margin-top: 0;
    grid-column: 1;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    margin-top: 5px;
  }

  @media (min-width: ${MIN_4K}px) {
    margin-top: 0;
  }
`;

export const Container = styled.div`
  ${MenuContainer} {
    justify-content: end;
    right: 0;
    left: auto;
  }
`;

export const SlidesList = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: #000;
`;
