import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IWidget } from 'interfaces/widgets';
import { IMediaVideo } from 'interfaces/media';

import Slides from './Slides';

const query = graphql`
  query {
    strapiWidgets(title: {eq: "teamsSlider"}, page: {eq: "culture"}) {
      title
      header
      strapiId
      slide {
        header
        id
        title
        media {
          ...MediaVideoFragment
        }
        text {
          text
        }
      }
    }
  }
`;

interface ITeamsSlider {
  strapiWidgets: IWidget<null, IMediaVideo>;
}

const TeamsSlider: FC = () => (
  <StaticQuery
    query={query}
    render={(data: ITeamsSlider) => data?.strapiWidgets && (
      <Slides {...data.strapiWidgets} />
    )}
  />
);

export default TeamsSlider;
