import React, {
  FC, useState, useRef, useEffect,
} from 'react';

import { IStrapiVacancy } from 'interfaces/vacancy';
import { IStrapiLocation } from 'interfaces/location';
import { IStrapiDepartment } from 'interfaces/departments';

import FilterItem from './FilterItem/FilterItem';
import {
  Container, PositionsList, ExpandButton, CloseButton, Wrapper, CloseIcon,
} from './styled';

interface IChange {
  type: 'location' | 'department' | '';
  id: string | number;
}

interface IFilter {
  activeFilter: IChange;
  activePositions: IStrapiVacancy[];
  className?: string;
  departments: IStrapiDepartment[];
  locations: IStrapiLocation[];
  onSelect: ({ type, id }: IChange) => void;
}

const Filter: FC<IFilter> = ({
  activeFilter,
  activePositions = [],
  className = '',
  departments,
  locations,
  onSelect,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [opened, setOpen] = useState<boolean>(false);

  const handleSelect = ({ id, type }: IChange) => {
    onSelect({ id, type });
    setOpen(false);
  };

  const getCount = ({ type, id }: IChange): number => activePositions?.filter(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    (item) => String(item[type]?.id) === String(id),
  )?.length || 0;

  const isActive = ({ type, id }): boolean => type === activeFilter.type
    && id === activeFilter.id;

  const toggle = () => setOpen(!opened);

  const handleClose = ({ target }: Event): void => {
    if (target instanceof HTMLElement
      && wrapperRef.current
      && !wrapperRef.current?.contains(target)
    ) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClose);

    return () => {
      document.removeEventListener('mousedown', handleClose);
    };
  }, [wrapperRef]);

  return (
    <Container className={className} ref={wrapperRef}>
      <ExpandButton onClick={toggle}>
        Filters
        <CloseIcon className={opened ? 'opened' : ''} />
      </ExpandButton>
      <Wrapper className={opened ? 'opened' : ''}>
        <PositionsList>
          <FilterItem
            name="All"
            type="department"
            count={-1}
            onSelect={handleSelect}
            isActive={isActive({ type: 'department', id: -1 })}
          />

          {departments.map(({ name, id, vacancies }) => vacancies.length > 0 && (
            <FilterItem
              name={name}
              key={`filter-department_${id}`}
              type="department"
              id={id}
              onSelect={handleSelect}
              isActive={isActive({ type: 'department', id })}
              count={getCount({ type: 'department', id })}
            />
          ))}
        </PositionsList>

        <PositionsList>
          <FilterItem
            name="Locations"
            type="location"
            count={-1}
            onSelect={handleSelect}
            isActive={isActive({ type: 'location', id: -1 })}
          />

          {locations.map(({ country, id, vacancies }) => vacancies.length > 0 && (
            <FilterItem
              name={country}
              key={`filter-places${id}`}
              type="location"
              id={id}
              onSelect={handleSelect}
              isActive={isActive({ type: 'location', id })}
              count={getCount({ type: 'location', id })}
            />
          ))}
        </PositionsList>
        <CloseButton onClick={toggle}>Close</CloseButton>
      </Wrapper>
    </Container>
  );
};

export default Filter;
