import React, { forwardRef, Ref } from 'react';

import { ISlide } from 'interfaces/ui';

import {
  SlideTitle, SlideText, Content, Video, SliderContent, Background,
} from './styled';

interface ISlideItem extends ISlide {
  videoRef: Ref<HTMLVideoElement>;
  index: number;
}

const SlideItem = forwardRef<HTMLDivElement, ISlideItem>(
  ({
    title, text, src, srcSet, videoRef, index,
  }, ref) => (
    <Content ref={ref} className={index === 0 ? 'active' : ''}>
      <SliderContent>
        <SlideTitle>{title}</SlideTitle>
        <SlideText>{text}</SlideText>
      </SliderContent>
      <Background>
        <Video
          ref={videoRef}
          poster={src}
          src={srcSet[1080]}
          srcSet={srcSet}
          playOnVisible={false}
        />
      </Background>
    </Content>
  ),
);

export default SlideItem;
