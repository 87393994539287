import styled from '@emotion/styled';

import {
  MAX_MOB_WIDTH, MAX_TABLET_WIDTH, MIN_2K, MIN_4K,
} from 'constants/sizes';
import { Layout as UILayout, SectionTitle } from 'UI';

export const Header = styled(SectionTitle)`
  grid-row: 1;
  grid-column: 1/-1;

  color: var(--red);

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    margin: 0;
  }
`;

export const Title = styled.h3`
  grid-column: 1/7;
  grid-row: 2;
  padding-bottom: 140px;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/-1;
    padding-bottom: 140px;
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) and (max-height: 640px) {
    padding-bottom: 60px;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding-bottom: 0;
  }

  @media (min-width: ${MIN_2K}px) {
    padding-bottom: 200px;
  }

  @media (min-width: ${MIN_4K}px) {
    padding-bottom: 400px;
  }
`;

const Paragraph = styled.p`
  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 4/-1;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 2/-1;
  }
`;

export const TextOne = styled(Paragraph)`
  grid-column: 7/9;
  grid-row: 3;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 2/-1;
  }
`;

export const TextTwo = styled(Paragraph)`
  grid-column: 9/11;
  grid-row: 3;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-row: 4;
    grid-column: 2/-1;
  }
`;

export const Layout = styled(UILayout)`
  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding-top: 0;
  }
`;
