import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IWidget } from 'interfaces/widgets';

import {
  Title, Header, Layout, TextOne, TextTwo,
} from './style';

const query = graphql`
  query {
    strapiWidgets(title: {eq: "team"}, page: {eq: "culture"}) {
      title
      strapiId
      header
      text {
        id
        text
      }
    }
  }
`;

interface IWelcomeBlock {
  strapiWidgets: IWidget<null, null>;
}

const Team: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IWelcomeBlock) => {
      if (!data?.strapiWidgets) return null;
      const { header = '', text = [] } = data.strapiWidgets;

      return (
        <Layout>
          <Header>{header}</Header>
          <Title>{text[0]?.text}</Title>
          <TextOne>{text[1]?.text}</TextOne>
          <TextTwo>{text[2]?.text}</TextTwo>
        </Layout>
      );
    }}
  />
);

export default Team;
