import React, {
  useRef, createRef, FC, useEffect,
} from 'react';

import { IWidget } from 'interfaces/widgets';
import { IMediaVideo } from 'interfaces/media';

import { ScrollableSlider, Layout } from 'UI';

import SlideItem from './TeamsSlides/SlideIntem';

import { Container, TopText, SlidesList } from './styled';

interface ISliderRef extends HTMLDivElement {
  setNewSlide: (index: number, smooth?: boolean) => void;
}

const TeamsSlider: FC<IWidget<null, IMediaVideo>> = ({ slide, header }) => {
  const sliderRef = useRef<ISliderRef>(null);
  const containerRef = useRef<HTMLDivElement>();
  const voidFunction = () => {};

  /**
   * Slides refs
   */
  const slideRefs = useRef(slide.map(() => createRef<HTMLDivElement>()));
  const videoRefs = useRef(slide.map(() => createRef<HTMLVideoElement>()));

  const isScroll = false;
  let isPlaySlide: ReturnType<typeof setTimeout>;

  let activeSlide = 0;
  let isVisible = false;
  let isPaused = true;
  const slidesList: string[] = slide.map(({ title }) => title);

  const setActiveClass = (item: number) => {
    const slidesBlocks = slideRefs.current;

    if (!slidesBlocks) return;

    slidesBlocks.forEach(({ current }, index) => (index === item ? current?.classList.add('active') : current?.classList.remove('active')));
  };

  const pauseSlides = (item = -1) => {
    if (isPaused) return;

    for (let i = 0; i < slide.length; i += 1) {
      const video = videoRefs.current[i].current;

      if (i !== item && video !== null) {
        video.pause();
        video.currentTime = 0;
      }
    }

    isPaused = true;
  };

  const playSlide = async (index: number) => {
    if (isScroll) return;

    const video = videoRefs.current?.[index]?.current;

    if (!video || !isVisible) return;

    video.currentTime = 0;

    await video.play();

    isPaused = false;
    video.poster = '';

    clearTimeout(isPlaySlide);

    isPlaySlide = setTimeout(() => {
      const isPlaying = video.currentTime > 0
        && !video.paused
        && !video.ended
        && video.readyState > video.HAVE_CURRENT_DATA;

      if (isPlaying && video.duration > 0) {
        sliderRef.current?.setNewSlide(index + 1, false);
      }
    }, (Number(video.duration) - 2) * 1000);
  };

  const handleChangeSlide = (item: number = activeSlide) => {
    if (!isVisible) return;

    activeSlide = item;

    setActiveClass(item);
    pauseSlides(item);
    playSlide(item).then(voidFunction, voidFunction);
  };

  const handleVisibility = (visibility: boolean) => {
    if (isVisible === visibility) return;

    isVisible = visibility;

    if (!visibility) {
      pauseSlides();
      clearTimeout(isPlaySlide);
    } else handleChangeSlide(activeSlide);
  };

  useEffect(() => {
    if (containerRef.current) {
      setActiveClass(0);
    }
  }, []);

  const getVideoSrc = (media: IMediaVideo) => {
    const {
      localFile: { FHD, qHD },
    } = media;

    return {
      1080: FHD.path,
      540: qHD.path,
    };
  };

  return (
    <Container ref={containerRef}>
      <ScrollableSlider
        ref={sliderRef}
        items={slidesList}
        onChangeSlide={handleChangeSlide}
        onChangeVisibility={handleVisibility}
        appear="scaleVisible"
      >
        <Layout withPaddings>
          <TopText>{header}</TopText>
        </Layout>
        <SlidesList>
          {slide.map((slideItem, index: number) => (
            <SlideItem
              index={index}
              title={slideItem.header}
              text={slideItem.text[0]?.text}
              name={slideItem.title}
              src={slideItem.media?.localFile?.videoScreenshots?.[0]?.publicURL}
              srcSet={getVideoSrc(slideItem.media)}
              key={`slider-${slideItem.id}`}
              ref={slideRefs.current[index]}
              videoRef={videoRefs.current[index]}
            />
          ))}
        </SlidesList>
      </ScrollableSlider>
    </Container>
  );
};

export default TeamsSlider;
