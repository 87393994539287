import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IWidget } from 'interfaces/widgets';
import { IMediaSvg } from 'interfaces/media';

import {
  Wrapper, Title, Item, Country, City, Picture,
} from './Map.styled';

const query = graphql`
  query {
    strapiWidgets(title: {eq: "locations"}, page: {eq: "culture"}) {
      title
      strapiId
      header
      text {
        id
        title
        text
      }
      media {
        ...MediaSVGFragment
      }
    }
  }
`;

interface IMap {
  strapiWidgets: IWidget<IMediaSvg, null>;
}

const Map: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IMap) => {
      if (!data?.strapiWidgets) return null;
      const { header, text = [], media } = data.strapiWidgets;

      return (
        <Wrapper>
          <Title>{header}</Title>

          {text.map((item) => (
            <Item key={`places_${item.id}`}>
              <Country>{item.title}</Country>
              <City>{item.text}</City>
            </Item>
          ))}

          <Picture media={media?.[0]} wrapper="div" />
        </Wrapper>
      );
    }}
  />
);

export default Map;
