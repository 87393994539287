import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IWidget } from 'interfaces/widgets';
import { IMediaImage } from 'interfaces/media';

import { Picture, Container } from './styled';

const query = graphql`
  query {
    strapiWidgets(title: {eq: "mission"}, page: {eq: "culture"}) {
      strapiId
      media {
        ...MediaImageFragment
      }
    }
  }
`;

interface IWelcomeBlock {
  strapiWidgets: IWidget<IMediaImage, null>;
}

const ImageBlock: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IWelcomeBlock) => data?.strapiWidgets && (
      <Container>
        <Picture {...data.strapiWidgets?.media?.[0]} />
      </Container>
    )}
  />
);

export default ImageBlock;
