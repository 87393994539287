import styled from '@emotion/styled';

export const Container = styled.li`
  cursor: pointer;
  outline: none;

  :hover {
    color: #000;
  }
`;

export const Count = styled.sup`
  font-weight: 500;
  font-size: 0.6em;
  display: inline-block;
  margin-left: 0.4em;
  transform: translateY(-0.7em);
`;
