import styled from '@emotion/styled';
import { Parallax } from 'react-scroll-parallax';

import { Layout, MiddleText, GatsbyPicture } from 'UI';

import { MAX_MOB_WIDTH, MAX_TABLET_WIDTH } from 'constants/sizes';

export const Contaiener = styled.div`
  position: relative;
  overflow: hidden;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    padding-bottom: 0;
  }
`;

export const Picture = styled(GatsbyPicture)``;

export const Wrapper = styled(Layout)`
  position: relative;
  z-index: 0;
  row-gap: 3em;
`;

export const Title = styled(MiddleText)`
  grid-row: 2;
  grid-column: 1/8;
  margin-bottom: 3em;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/-1;
  }
`;

export const Subtitle = styled.p`
  grid-row: 3;
  grid-column: 8/-1;
  margin-bottom: 1em;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 4/-1;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 2/-1;
  }
`;

export const ParallaxOne = styled(Parallax)`
  width: 100%;
  height: 52.7%;
  grid-row: 4;
  grid-column: 1/-1;
  margin-bottom: 4em;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    width: 100vw;
    margin: 0 -20px;
  }
`;

const ParallaxImage = styled(Parallax)`
  position: relative;
`;

export const ParallaxTwo = styled(ParallaxImage)`
  grid-row: 5;
  grid-column: 2/8;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 2/5;
    margin-top: 3em;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 2/-1;
  }
`;

export const ParallaxThree = styled(ParallaxImage)`
  grid-row: 6;
  grid-column: 1/3;
  margin-bottom: -50%;
  margin-top: -70%;
  z-index: -1;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/3;
    margin-bottom: 10%;
  }
`;

export const ParallaxFour = styled(ParallaxImage)`
  grid-row: 8;
  grid-column: 1/7;
  margin-top: 4em;
  z-index: 1;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    margin-top: 10%;
    grid-column: 1/4;
  }
`;

export const ParallaxFive = styled(ParallaxImage)`
  grid-row: 9;
  grid-column: 4/10;
  margin-top: -120%;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 3/6;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 2/4;
    margin: 0 -30px;
    margin-top: -25%;
  }
`;

export const ParallaxSix = styled(ParallaxImage)`
  grid-row: 10;
  grid-column: 9/-1;
  z-index: -1;
  margin-top: -230%;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 5/-1;
    margin-top: -165%;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    margin-top: -30%;
    grid-column: 3/-1;
  }
`;

export const Text = styled(MiddleText)``;

export const ParallaxText = styled(Parallax)`
  grid-row: 7;
  grid-column: 5/-1;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 3/-1;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 1/-1;
  }
`;
