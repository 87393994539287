import styled from '@emotion/styled';

import { SearchIcon as UISearchIcon } from 'UI/Icons';

export const Container = styled.div`
  position: relative;
  height: 2em;
  display: flex;
`;

export const SearchIcon = styled(UISearchIcon)`
  display: inline;
  margin-right: 0.5em;
  fill: var(--gray);
`;

export const Label = styled.label`
  position: absolute;
  left: 1.6em;
  top: 0;
  height: 2em;
  line-height: 2em;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  color: var(--gray);

  ::after {
    content: '...';
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  background: none;

  :focus {
    outline: none;
  }

  :focus,
  :active,
  :valid {
    ~ ${Label} {
      transform: translateY(-1.3em) scale(0.8);
      transform-origin: center left;
      font-weight: 600;
      color: #aaa;

      ::after {
        content: '';
      }
    }
  }
`;
