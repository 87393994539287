import styled from '@emotion/styled';

import { GatsbySvg, Layout, MiddleText } from 'UI';
import { MAX_TABLET_WIDTH, MAX_MOB_WIDTH } from 'constants/sizes';

export const Wrapper = styled(Layout)`
  background: #000;
  min-height: 100vh;
  position: relative;
  padding-top: 3em;
  align-content: start;
  row-gap: 3em;
  overflow: hidden;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    row-gap: 40px;
  }
`;

export const Picture = styled(GatsbySvg)`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;

  svg {
    position: absolute;
    width: 120%;
    left: -10%;
    bottom: -4em;
    height: auto;

    .map {
      fill: #fff;
      opacity: 0.14;
    }

    .point {
      stroke: var(--red);
      stroke-opacity: 0.23;
      stroke-width: 15px;
      paint-order: markers stroke fill;
    }

    @media (max-width: ${MAX_TABLET_WIDTH}px) {
      left: -20%;
      width: 150%;
      top: 15em;
      bottom: auto;

      .point {
        stroke-width: 2vw;
      }
    }

    @media (max-width: ${MAX_MOB_WIDTH}px) {
      top: 35%;

      .map {
        opacity: 0.2;
      }

      .point {
        stroke-width: 25px;
      }
    }
  }
`;

export const Item = styled.div`
  z-index: 1;
  grid-column: 6/8;

  &:nth-of-type(2n) {
    grid-column: 9/-1;
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-row-start: 2/3;
    grid-column: 1/4;

    &:nth-of-type(2n) {
      grid-column: 4/-1;
    }
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-row-start: 2/3;
    grid-column: 1/3;

    &:nth-of-type(2n) {
      grid-column: 3/-1;
    }
  }
`;

export const Title = styled.h3`
  color: var(--white);
  z-index: 1;
  grid-column: 1/3;
  grid-row: 1;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    margin-bottom: 40px;
    grid-column: 1/-1;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    margin-bottom: 8px;
  }
`;

export const Country = styled.p`
  color: var(--white);
  margin-bottom: 4px;
`;

export const City = styled(MiddleText)`
  color: var(--white);
`;
