import React, { FC, useState } from 'react';

import { IStrapiVacancy } from 'interfaces/vacancy';
import { IStrapiLocation } from 'interfaces/location';
import { IStrapiDepartment } from 'interfaces/departments';

import {
  Search, Count, Title, Filter, Layout, PositionsList,
} from './styled';

interface IFilter {
  type: 'location' | 'department' | '';
  id: string | number;
}

interface IJobsList {
  departments: IStrapiDepartment[];
  locations: IStrapiLocation[];
  vacancies: IStrapiVacancy[];
  totalCount: number;
}

const Jobs: FC<IJobsList> = ({
  departments, locations, vacancies, totalCount,
}) => {
  const [search, setSearch] = useState<string>(null);
  const [filter, setFilter] = useState<IFilter>({ type: '', id: -1 });

  const getSearchedPositions = (): IStrapiVacancy[] => {
    if (!search || search.length === 0) {
      return vacancies || [];
    }

    const searched = vacancies?.filter(
      ({
        location, department, title,
      }) => location.city.toLowerCase().includes(search)
          || location.country.toLowerCase().includes(search)
          || department.name.toLowerCase().includes(search)
          || title.toLowerCase().includes(search),
    ) || [];

    return searched || [];
  };

  const getFilteredPositions = (): IStrapiVacancy[] => {
    const { type = '', id } = filter;
    const searched = getSearchedPositions();

    if (id === -1 || !type || type.length === 0) {
      return searched;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const filtered = searched?.filter((item) => String(item[type]?.id) === String(id));

    return filtered || [];
  };

  return (
    <Layout withPaddings>
      <Search onChange={setSearch} />
      <Title>
        Browse
        <Count>
          {` ${totalCount} `}
        </Count>
        <br />
        open positions
      </Title>
      <Filter
        locations={locations}
        departments={departments}
        activePositions={getSearchedPositions()}
        activeFilter={filter}
        onSelect={setFilter}
      />
      <PositionsList positions={getFilteredPositions()} />
    </Layout>
  );
};

export default Jobs;
