import React, { FC, useState, useEffect } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import Markdown from 'markdown-to-jsx';

import { IComponentWidgetSlide, IWidget } from 'interfaces/widgets';
import { IMediaImage, IMediaVideo } from 'interfaces/media';

import {
  MAX_MOB_WIDTH, MAX_TABLET_WIDTH, MIN_2K, MIN_4K,
} from 'constants/sizes';
import { SectionTitle, Video } from 'UI';

import {
  Contaiener,
  Title,
  Text,
  Subtitle,
  Wrapper,
  Picture,
  ParallaxText,
  ParallaxOne,
  ParallaxTwo,
  ParallaxThree,
  ParallaxFour,
  ParallaxFive,
  ParallaxSix,
} from './style';

interface IParallaxSize {
  top: string[];
  mid: string[];
  bottom: string[];
}

const parallaxSizes: IParallaxSize[] = [
  {
    top: ['200px', '-200px'],
    mid: ['100px', '-100px'],
    bottom: ['50px', '-50px'],
  },
  {
    top: ['300px', '-300px'],
    mid: ['150px', '-150px'],
    bottom: ['75px', '-75px'],
  },
  {
    top: ['600px', '-600px'],
    mid: ['300px', '-300px'],
    bottom: ['150px', '-150px'],
  },
  {
    top: ['100px', '-100px'],
    mid: ['50px', '-50px'],
    bottom: ['20px', '-20px'],
  },
  {
    top: ['0', '0'],
    mid: ['0', '0'],
    bottom: ['50px', '-50px'],
  },
];

const WayRaversBlock: FC<IWidget<null, unknown>> = ({ header, text, slide }) => {
  const [sizes, setSizes] = useState<IParallaxSize>(parallaxSizes[0]);
  let timeout: ReturnType<typeof setTimeout>;

  const setParallaxSizes = () => {
    if (window.innerWidth <= MAX_MOB_WIDTH) {
      setSizes(parallaxSizes[4]);
    } else if (window.innerWidth <= MAX_TABLET_WIDTH) {
      setSizes(parallaxSizes[3]);
    } else if (window.innerWidth >= MIN_4K) {
      setSizes(parallaxSizes[1]);
    } else if (window.innerWidth >= MIN_2K) {
      setSizes(parallaxSizes[2]);
    } else {
      setSizes(parallaxSizes[0]);
    }
  };

  const handleResize = () => {
    clearTimeout(timeout);
    timeout = setTimeout(setParallaxSizes, 400);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getVideoSrc = (media: IMediaVideo) => {
    const {
      localFile: { FHD, HD, qHD },
    } = media;

    return {
      1080: FHD?.path,
      720: HD?.path,
      540: qHD?.path,
    };
  };

  const videoSlide = slide.find(({ title }) => title === 'video') as IComponentWidgetSlide<IMediaVideo>;
  const imageSlides = slide.filter(({ title }) => title !== 'video') as IComponentWidgetSlide<IMediaImage>[];

  return (
    <Contaiener>
      <ParallaxProvider>
        <Wrapper withPaddings>
          <SectionTitle>{header}</SectionTitle>

          <Title>
            {text.find(({ title }) => title === 'title')?.text}
          </Title>

          <Subtitle>
            {text.find(({ title }) => title === 'subtitle')?.text}
          </Subtitle>

          <ParallaxOne>
            <Video
              loop
              src={getVideoSrc(videoSlide?.media)?.[1080]}
              srcSet={getVideoSrc(videoSlide?.media)}
              poster={videoSlide?.media?.localFile?.videoScreenshots?.[0]?.publicURL}
              type="video/mp4"
            />
          </ParallaxOne>

          <ParallaxTwo y={sizes.bottom}>
            <Picture {...imageSlides?.[0].media} />
          </ParallaxTwo>

          <ParallaxThree y={sizes.top}>
            <Picture {...imageSlides?.[1].media} />
          </ParallaxThree>

          <ParallaxText y={sizes.mid}>
            <Text>
              <Markdown>
                {text.find(({ title }) => title === 'parallax')?.text}
              </Markdown>
            </Text>
          </ParallaxText>

          <ParallaxFour y={sizes.mid}>
            <Picture {...imageSlides?.[2].media} />
          </ParallaxFour>

          <ParallaxFive y={sizes.bottom}>
            <Picture {...imageSlides?.[3].media} />
          </ParallaxFive>

          <ParallaxSix y={sizes.top}>
            <Picture {...imageSlides?.[4].media} />
          </ParallaxSix>
        </Wrapper>
      </ParallaxProvider>
    </Contaiener>
  );
};

export default WayRaversBlock;
