import React, { FC, useState } from 'react';

import { IStrapiVacancy } from 'interfaces/vacancy';

import PositionContainer from './Position';

import {
  Container,
  Title,
  Item,
  Department,
  CloseIcon,
  MoreIcon,
  ShowMore,
  LineOne,
  LineTwo,
} from './styled';

interface IPositionsList {
  positions: IStrapiVacancy[];
  className?: string;
}

const PositionsList: FC<IPositionsList> = ({ className = '', positions = [] }) => {
  const [expanded, setExpanded] = useState<number[]>([]);
  const [maxShown, setShown] = useState<number>(4);

  const toggle = (id: number) => {
    if (expanded.includes(id)) {
      setExpanded(expanded.filter((activeId) => activeId !== id));
    } else {
      setExpanded(expanded.concat([id]));
    }
  };

  const toggleCount = () => {
    setShown(maxShown > 4 ? 4 : 100);
  };

  return (
    <Container className={className}>
      {positions?.map(
        (job, index) => index < maxShown && (
        <Item key={`position_${job.id}`} className={expanded.includes(job.id) ? 'active' : ''}>
          <Department title={job.department.name}>
            {job.department.name}
            {job?.location?.city && ` | ${job.location.city}`}
            {job?.location?.country && `, ${job.location.country}`}
            {(job?.location && job.remote) && ' (Office or Remote)'}
            {!job.location && '| Remote'}
          </Department>
          <Title title={job.title} onClick={() => toggle(job.id)}>
            <span>{job.title}</span>
            <CloseIcon>
              <LineOne />
              <LineTwo />
            </CloseIcon>
          </Title>
          <PositionContainer opened={expanded.includes(job.id)} position={job} />
        </Item>
        ),
      )}
      <ShowMore onClick={toggleCount}>
        {maxShown > 4 ? 'Show less' : 'Show more'}
        <MoreIcon className={maxShown > 4 ? 'opened' : ''} />
      </ShowMore>
    </Container>
  );
};

export default PositionsList;
