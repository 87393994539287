import styled from '@emotion/styled';

import { MAX_TABLET_WIDTH, MAX_MOB_WIDTH } from 'constants/sizes';
import { Layout as UILayout } from 'UI';

import UIPositionsList from './PositionsList/PositionsList';
import UISearch from './Search/Search';
import UIFilter from './Filter/Filter';

export const Layout = styled(UILayout)`
  position: relative;
  min-height: 100vh;
  grid-template-rows: max-content;
  align-content: start;
`;

export const Search = styled(UISearch)`
  grid-column: 1/-1;
  grid-row: 1;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/6;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 1/4;
  }
`;

export const Title = styled.h3`
  grid-column: 1/4;
  grid-row: 2;
  margin-bottom: 2.5em;
`;

export const Count = styled.span`
  color: var(--red);
`;

export const Data = styled.div`
  grid-row: 3;
  grid-column: 1/-1;
`;

export const Filter = styled(UIFilter)`
  grid-column: 1/3;
  grid-row: 4;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-row: 1;
    grid-column: 6;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 4;
  }
`;

export const PositionsList = styled(UIPositionsList)`
  grid-column: 5/-1;
  grid-row: 4;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 2/-1;
    grid-row: 3;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 1/-1;
  }
`;
