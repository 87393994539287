import React, { FC } from 'react';

import { IIcon } from './interfaces';

const SearchIcon: FC<IIcon> = ({ className = '', ...props }) => (
  <svg
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path d="m4.75 0a4.75 4.75 0 0 0-4.75 4.75 4.75 4.75 0 0 0 4.75 4.75 4.75 4.75 0 0 0 4.75-4.75 4.75 4.75 0 0 0-4.75-4.75zm-0.06055 0.75a4 4 0 0 1 0.06055 0 4 4 0 0 1 4 4 4 4 0 0 1-4 4 4 4 0 0 1-4-4 4 4 0 0 1 3.939-4z" />
    <rect
      transform="rotate(135)"
      x="-.7479"
      y="-16.66"
      width="1.5"
      height="5.9"
      rx=".75"
      ry=".75"
    />
  </svg>
);

export default SearchIcon;
