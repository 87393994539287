import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IStrapiVacancy } from 'interfaces/vacancy';
import { IStrapiLocation } from 'interfaces/location';
import { IStrapiDepartment } from 'interfaces/departments';

import JobsList from './JobsList';

const query = graphql`
  query {
    allStrapiVacancies {
      totalCount
      nodes {
        id: strapiId
        title
        greenhouse
        remote
        department {
          name
          id
        }
        location {
          city
          country
          id
        }
        section {
          text
          title
          id
        }
      }
    }

    allStrapiLocations {
      nodes {
        city
        country
        id: strapiId
        vacancies {
          id
        }
      }
    }

    allStrapiDepartments {
      nodes {
        name
        id: strapiId
        vacancies {
          id
        }
      }
    }
  }
`;

interface IJobsQuery {
  allStrapiVacancies: {
    nodes: IStrapiVacancy[];
    totalCount: number;
  };
  allStrapiDepartments: {
    nodes: IStrapiDepartment[];
  };
  allStrapiLocations: {
    nodes: IStrapiLocation[];
  };
}

const Jobs: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IJobsQuery) => (
      <JobsList
        vacancies={data.allStrapiVacancies.nodes}
        locations={data.allStrapiLocations.nodes}
        departments={data.allStrapiDepartments.nodes}
        totalCount={data.allStrapiVacancies.totalCount}
      />
    )}
  />
);

export default Jobs;
