import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IWidget } from 'interfaces/widgets';

import WayRaversBlock from './WayRawersBlock';

const query = graphql`
  query {
    strapiWidgets(title: {eq: "wayravers"}, page: {eq: "culture"}) {
      title
      header
      strapiId
      text {
        id
        title
        text
      }
      slide {
        title
        media {
          ...MediaVideoFragment
          ...MediaImageFragment
        }
      }
    }
  }
`;

interface IValues {
  strapiWidgets: IWidget<null, unknown>;
}

const WayRavers: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IValues) => data?.strapiWidgets && (
      <WayRaversBlock {...data.strapiWidgets} />
    )}
  />
);

export default WayRavers;
