import styled from '@emotion/styled';

import { MAX_MOB_WIDTH, MAX_TABLET_WIDTH, MIN_2K } from 'constants/sizes';
import { Layout, MiddleText, SectionTitle } from 'UI';

import ValueItem from './ValueItem/ValueItem';

export const Container = styled(Layout)`
  align-items: start;
  grid-template-areas:
    'a a a a a a a a a a'
    'b b b b b q q q q q'
    's s c c c c c c c c'
    's s c c c c c c c c'
    's s c c c c c c c c'
    's s d d d d d d d d';

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-template-areas:
      'a a a a a a'
      'b b b b b b'
      's s c c c c'
      's s d d d d';
  }

  @media (min-width: ${MIN_2K}px) {
    grid-template-areas:
      'a a a a a a a a a a'
      'b b b b b q q q q q'
      's s s s c c c c c c'
      's s s s c c c c c c'
      's s s s c c c c c c'
      's s s s d d d d d d';
  }
`;

export const Title = styled.h3`
  grid-area: b;
  margin-bottom: 6rem;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    margin-bottom: 2rem;
  }
`;

export const Header = styled(SectionTitle)`
  grid-area: a;
`;

export const Text = styled(MiddleText)`
  grid-row: d;
  justify-items: end;
  grid-column: span 4;
  margin-top: 6rem;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-row: auto;
    grid-column: 3/-1;
    margin-top: 1em;
  }

  @media (min-width: ${MIN_2K}px) {
    grid-column: span 3;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 1/-1;
  }
`;

export const Space = styled.div`
  grid-area: s;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column-end: -1;
  }
`;

export const SpaceOne = styled.div`
  grid-area: q;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    display: none;
  }
`;

export const Item = styled(ValueItem)`
  grid-column: span 4;
  margin-bottom: 1.2rem;

  @media (min-width: ${MIN_2K}px) {
    grid-column: span 3;
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-row: auto;
    grid-column: 3/-1;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 1/-1;
  }
`;
