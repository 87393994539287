import styled from '@emotion/styled';

import { MiddleText } from 'UI';

export const Title = styled(MiddleText)``;

export const Plus = styled.div`
  width: 1.4rem;
  height: 1.4rem;
  position: relative;
  transition: transform 0.4s ease-in-out;

  ::before,
  ::after {
    display: block;
    content: '';
    width: 100%;
    height: 2px;
    background: var(--red);
    position: absolute;
    top: calc(50% - 1px);
  }

  ::after {
    top: 0;
    height: 100%;
    width: 2px;
    left: calc(50% - 1px);
  }
`;

export const Text = styled.p`
  height: 0;
  opacity: 0;
  position: relative;
  overflow: hidden;
  transition: 0.4s ease-in-out;
`;

export const Container = styled.div`
  padding-left: 1.4rem;
  border-left: 2px solid var(--red);
  display: grid;
  grid-auto-flow: row;
  gap: 0.8rem;
  cursor: pointer;

  &.opened {
    ${Text} {
      opacity: 1;
    }

    ${Plus} {
      transform: rotate(45deg);
    }
  }
`;
