import React, { FC } from 'react';

import { Container, Count } from './styled';

interface IOnSelect {
  type: string;
  id: string | number;
}

interface IFilterItem {
  id?: string | number;
  name: string;
  type: string;
  count?: number;
  isActive: boolean;
  onSelect: (data: IOnSelect) => void;
}

const FilterItem: FC<IFilterItem> = ({
  name, type, count = 0, isActive, onSelect, id = -1,
}) => (
  <Container
    tabIndex={0}
    title={name}
    onClick={() => onSelect({ id, type })}
    style={{ color: isActive ? 'black' : 'inherit' }}
  >
    {name.toLowerCase() === 'automotive' ? 'Platform' : name}

    {count >= 0 && <Count>{count}</Count>}
  </Container>
);

export default FilterItem;
